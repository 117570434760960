<template>
    <div class="UserAnaly_A">
        <div class="rana_con">
            <div class="rana_con_title">
                筛选最终确定的目标用户群体（3-5个）
            </div>
            <!-- 内容 -->
            <div class="rana_con_content">
                <div class="arrow_box">
                    <div class="arrow_box_left">
                        <img src="./../../../assets/img/arrow_left.png" alt="" @click="prevBox()" v-show="totalnum>5">
                    </div>
                    <ul class="arrow_box_cente">
                        <li v-for="(item,index) in caseList" :key="index" :class="item.val==1?'li_up':'li_down'" @click="caseBox(item,index)">
                            <div class="tiem_one">
                                主语：{{item.role}}
                            </div>
                            <div class="tiem_all">
                                <div class="tiem_one" v-for="(items,indexs) in item.tag.slice(0,3)" :key="indexs">
                                    <img src="./../../../assets/img/label.png" alt="">
                                    <span class="items_span">{{items}}</span>
                                </div>
                            </div>
                            <img class="line_img" src="./../../../assets/img/line.png" alt="">
                            <div class="line_cont" :title="item.desc2">
                                <span>
                                    {{item.desc2}}
                                </span>
                            </div>
                        </li>
                    </ul>
                    <div class="arrow_box_left">
                        <img src="./../../../assets/img/arrow_right.png" alt="" @click="nexBox()" v-show="totalnum>5">
                    </div>
                </div>
            </div>
            <!-- 按钮 -->
            <div class="butt_div">
                <div class="button_one" v-preventReClick @click="prevstep()">
                    <span>取消</span>
                </div>
                <div class="button_two" v-preventReClick @click="nextBotn()">
                    <span>确定</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            istrue:0,
            caseList:[],
            currentpage:1,
            totalnum:0
        };
    },
    methods: {
        getapp(){
            this.axios.projectS1P3({
                token:this.$store.state.token,
                id:this.$store.state.cla_id
            }).then(res=>{
                if(res.data.code==0){
                    var str = res.data.data.qrcode
                    this.$emit('info', str);
                    this.array = res.data.data.values
                    this.totalnum = res.data.data.values.length
                    const newarray = []
                    const allpage = Math.ceil(res.data.data.values.length / 5)
                    for (var i = 0; i < allpage; i++) {
                        newarray.push(res.data.data.values.slice(i * 5, i * 5 + 5))
                    }
                    this.newarrays = newarray
                    this.caseList = this.newarrays[this.currentpage - 1]
                    if(this.caseList==undefined){
                        this.caseList = this.newarrays[this.currentpage - 2];
                    }
                }else{
                     this.caseList = [] 
                }
            }).catch(err=>{

            })
        },
        caseBox(item,val){
            if(item.val==1){
                item.val = 0
            }else{
                item.val = 1
            }
        },
        // 上一页
        prevBox(){
            if(this.currentpage>1){
                this.istrue = 0
                this.currentpage = this.currentpage - 1
                this.caseList = this.newarrays[this.currentpage - 1]
            }else{
                this.currentpage = 1
            }
        },
        //下一页
        nexBox(){
            if(this.currentpage * 5 < this.totalnum){
                this.istrue = 0
                this.currentpage = this.currentpage + 1
                this.caseList = this.newarrays[this.currentpage - 1]
            }else{
                
            }
        },
        prevstep(){
            this.$router.push({name:'UserAnaly_B'})
        },
        nextBotn(){
            let array = this.newarrays
            var arr = []
            for(var i in array){
               arr = arr.concat(array[i])
            }
            this.axios.Sets1({
                token:this.$store.state.token,
                id:this.$store.state.cla_id,
                node:'p3',
                value:arr,
            }).then(res=>{
                if(res.data.code==0){
                    this.$router.push({name:'StudentPage'})
                }else{
                    this.$message.error(res.data.message)
                }
            }).catch(err=>{

            })
        }
    },
    mounted() {
        this.getapp()
    }
};
</script>
<style scoped>
@import './../../../assets/css/student/A/UserAnaly_A.css';
</style>